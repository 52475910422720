import { ReactElement } from 'react'
import { NextPageContext } from 'next'

import Container from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Meta from '@/components/base/Meta'
import Typography from '@/components/base/Typography'
import Layout from '@/components/layout/Layout'
import usePagePrecache from '@/hooks/usePagePrecache'

import { WPPageData } from '@/types'

const FourOhFour = () => {
  usePagePrecache(true, [])

  return (
    <div className="relative z-section flex h-full min-h-screen items-center justify-center">
      <Container>
        <Grid>
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <div className="flex flex-col space-y-5">
              <Typography variant="h1" className="text-center text-pink">
                404
              </Typography>
              <Typography
                paragraph
                variant="body"
                className="!mb-spacing-2xl text-center"
              >
                Page not found
              </Typography>
            </div>
          </div>
        </Grid>
      </Container>
    </div>
  )
}

FourOhFour.getLayout = function getLayout(
  page: ReactElement,
  props: NextPageContext & WPPageData
) {
  return (
    <Layout pageData={props}>
      <Meta pageData={props} />
      {page}
    </Layout>
  )
}

export default FourOhFour
