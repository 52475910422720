import { PropsWithChildren } from 'react'

import Footer from '@/components/layout/Footer'
import useChangePathname from '@/hooks/useChangePathname'

import { LenisProvider } from '@/contexts/lenis'
import { WPPageData } from '@/types'

type LayoutProps = PropsWithChildren<{ pageData: WPPageData }>

const Layout = ({ children, pageData }: LayoutProps) => {
  useChangePathname(pageData)

  return (
    <LenisProvider pageData={pageData}>
      {children}
      <Footer />
    </LenisProvider>
  )
}

export default Layout
