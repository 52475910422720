import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useLayoutStore } from '@/store/layout'
import { useLocaleStore } from '@/store/locale'
import { useRouterStore } from '@/store/router'

import { WPPageData } from '@/types'

const useChangePathname = (pageData: WPPageData) => {
  const router = useRouter()

  const setCurrentRoute = useRouterStore((state) => state.setCurrentRoute)
  const setCurrentLocale = useLocaleStore((state) => state.setCurrentLocale)
  const setCurrentLayout = useLayoutStore((state) => state.setCurrentLayout)

  useEffect(() => {
    setCurrentRoute(router.asPath)
    setCurrentLocale(router.locale)
    setCurrentLayout(pageData.acf?.layout)
  }, [
    pageData,
    router.asPath,
    router.locale,
    setCurrentRoute,
    setCurrentLocale,
    setCurrentLayout
  ])
}

export default useChangePathname
