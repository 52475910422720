import parse from 'html-react-parser'
import Head from 'next/head'

import { FRONT_BASE_URL } from '@/constants'

import { useStore } from '@/contexts/store'
import { WPPageData } from '@/types'

type MetaProps = {
  pageData: WPPageData
}

const Meta = ({ pageData }: MetaProps) => {
  const { strings, yoast } = useStore()
  const robotsContent = [
    pageData?.meta_robots?.is_robots_noindex ? 'noindex' : 'index',
    pageData?.meta_robots?.is_robots_nofollow ? 'nofollow' : 'follow'
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <Head>
      {parse(yoast || '')}
      {strings?.site_icon_url ? (
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={strings?.site_icon_url}
        />
      ) : (
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
      )}
      {parse(
        pageData?.yoast_head?.replace(/"\//g, `"${FRONT_BASE_URL}/`) || ''
      )}
      {robotsContent ? <meta name="robots" content={robotsContent} /> : null}

      {pageData?.wpml_translations?.length > 0
        ? pageData.wpml_translations.map((translation: any, index: number) => (
            <link
              key={`${translation.id}${index}`}
              rel="alternate"
              hrefLang={translation.code}
              href={`${FRONT_BASE_URL}${translation.href}`}
            />
          ))
        : null}

      {pageData?.xdefault_translation?.length > 0
        ? pageData.xdefault_translation.map(
            (translation: any, index: number) => (
              <link
                key={`${translation.id}${index}`}
                rel="alternate"
                hrefLang="x-default"
                href={`${FRONT_BASE_URL}${translation.href}`}
              />
            )
          )
        : null}
    </Head>
  )
}

export default Meta
