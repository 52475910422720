import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

import { useTheme } from '@/contexts/theme'

type ContainerProps = HTMLAttributes<HTMLDivElement> & { fluid?: boolean }

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, fluid, ...props }, ref) => {
    const { fluid: fluidTheme } = useTheme()

    return (
      <div
        ref={ref}
        className={classNames(
          'container',
          { 'max-w-full': fluidTheme || fluid },
          className
        )}
        {...props}
      />
    )
  }
)

export default Container
