import classNames from 'classnames'
import Link from 'next/link'

import Container from '@/components/base/Container'
import Logo from '@/components/base/Logo'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'

import { useStore } from '@/contexts/store'

const Footer = () => {
  const { menu, strings } = useStore()

  return (
    <footer className="relative z-footer pb-sm md:pb-xl">
      <Container fluid>
        <div className="rounded-sm bg-blue p-[2rem] md:p-lg">
          <div className="grid grid-cols-1 gap-x-[2rem] gap-y-[3rem] lg:grid-cols-4">
            <div className="col-span-1 lg:col-span-1">
              <div className="flex flex-col">
                <Link href="/" scroll={false} aria-label="Homepage">
                  <Logo />
                </Link>
              </div>
            </div>
            <div className="col-span-1 lg:col-span-1">
              <div className="grid gap-xs">
                <Typography variant="body" className="text-white">
                  {strings?.footer?.brand}
                </Typography>
                <Typography variant="small" className="text-white/50">
                  {strings?.footer?.address}
                </Typography>
                <Typography variant="small" className="text-white/50">
                  {strings?.footer?.cap}
                </Typography>
                <Typography variant="small" className="text-white/50">
                  {strings?.footer?.vat}
                </Typography>
                <Typography variant="small" className="text-white/50">
                  {strings?.footer?.tel}
                </Typography>
                <a href={`mailto:${strings?.footer?.email}`} className="flex">
                  <Typography variant="small" className="text-white/50">
                    {strings?.footer?.email}
                  </Typography>
                </a>
              </div>
            </div>
            <div className="col-span-1 lg:col-span-1">
              <div className="grid gap-xs">
                <Typography variant="body" className="text-white">
                  {strings?.legals}
                </Typography>
                <nav>
                  <ul className="grid gap-xs">
                    {menu?.legals_menu?.menu.map(({ url, target, title }) => (
                      <li key={title}>
                        <a href={url} target={target} className="flex">
                          <Typography variant="small" className="text-white/50">
                            {title}
                          </Typography>
                        </a>
                        {/* <Link href={url} className="flex">
                          <Typography variant="small" className="text-white/50">
                            {title}
                          </Typography>
                        </Link> */}
                      </li>
                    ))}
                  </ul>
                </nav>
                <Typography variant="small" className="mt-sm text-white/50">
                  {strings?.footer?.copy}
                </Typography>
              </div>
            </div>
            <div className="col-span-1 flex flex-col lg:col-span-1 lg:items-end">
              <ul className="flex space-x-xxs">
                {menu?.social_menu?.menu.map(({ url, classes }) => (
                  <li key={classes}>
                    <a
                      href={url}
                      target="_blank"
                      className="flex h-[4.4rem] w-[4.4rem] items-center justify-center rounded-full border border-white/10"
                      aria-label={classes}
                    >
                      <SVGIcon
                        symbol={`${classes}-icon`}
                        className={classNames('fill-white/80', {
                          'h-[1rem] w-[.6rem]': classes === 'facebook',
                          'h-[1.2rem] w-[1.2rem]': classes === 'instagram',
                          'h-[1rem] w-[1.2rem]': classes === 'linkedin'
                        })}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
